import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Plus, Minus, BookOpen, HelpCircle } from 'react-feather'
import classNames from 'classnames'
import * as accordionSectionStyles from './accordionSection.module.scss'

const AccordionSection = ({
    children,
    isOpen,
    label,
    sublabel,
    number,
    onClick,
    isQuiz,
    isLesson,
}) => {
    const textRef = useRef(null)

    useEffect(() => {
        if (textRef.current) {
            textRef.current.style.maxHeight = isOpen
                ? `${textRef.current.scrollHeight}px`
                : '0px'
        }
    }, [isOpen])

    const numberAndIconWrapperClasses = classNames({
        [accordionSectionStyles.numberAndIconWrapper]: isQuiz || isLesson,
    })

    return (
        <div
            onClick={() => onClick(label)}
            onKeyPress={() => onClick(label)}
            className={accordionSectionStyles.section}
            role="button"
            tabIndex={0}
        >
            <h3 className={accordionSectionStyles.labelWrapper}>
                <span>
                    <span className={numberAndIconWrapperClasses}>
                        {number && (
                            <span className={accordionSectionStyles.number}>
                                {number}.
                            </span>
                        )}
                        {isQuiz && (
                            <span className={accordionSectionStyles.icon}>
                                <HelpCircle /> - Quiz
                            </span>
                        )}
                        {isLesson && (
                            <span className={accordionSectionStyles.icon}>
                                <BookOpen />
                                <span> - Lektion</span>
                            </span>
                        )}
                    </span>
                    <span>{label}</span>
                </span>
                {isOpen ? (
                    <Minus color={'#e07a5f'} />
                ) : (
                    <Plus color={'#e07a5f'} />
                )}
            </h3>
            {sublabel && (
                <h4 className={accordionSectionStyles.sublabel}>{sublabel}</h4>
            )}
            <div
                ref={textRef}
                className={accordionSectionStyles.contentWrapper}
            >
                {children}
            </div>
        </div>
    )
}

AccordionSection.propTypes = {
    children: PropTypes.instanceOf(Object).isRequired,
    isOpen: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    number: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
}

export default AccordionSection
