import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import Tags from '../components/tags'
import * as cardStyling from './card.module.scss'

const Card = ({
    to,
    image,
    showBanner,
    bannerText,
    title,
    subtitle,
    bodyText,
    tags,
}) => {
    return (
        <Link to={to} className={cardStyling.cardWrapper}>
            {image && (
                <GatsbyImage
                    image={{ ...image.gatsbyImageData, aspectRatio: 16 / 10 }}
                    alt={image.title}
                    className={'image-border-effect'}
                />
            )}
            {showBanner && (
                <span className={cardStyling.banner}>{bannerText}</span>
            )}
            {tags && (
                <span className={cardStyling.tagsWrapper}>
                    <Tags tags={tags} />
                </span>
            )}
            <h3 className={cardStyling.text + ' ' + cardStyling.title}>
                {title}
            </h3>
            {subtitle && (
                <h5 className={cardStyling.text + ' ' + cardStyling.subtitle}>
                    {subtitle}
                </h5>
            )}
            <p className={cardStyling.text}>{bodyText}</p>
        </Link>
    )
}

Card.propTypes = {
    to: PropTypes.string.isRequired,
    image: PropTypes.object,
    title: PropTypes.string.isRequired,
    bodyText: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    showBanner: PropTypes.bool,
    bannerText: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
}

export default Card
