import React, { useState } from 'react'
import PropTypes from 'prop-types'
import AccordionSection from './accordionSection'
import * as accordionStyles from './accordion.module.scss'

const Accordion = ({ children, allowMultipleOpen }) => {
    const defaultOpenSections = {}

    children.forEach((child) => {
        if (child.props.isOpen) {
            defaultOpenSections[child.props.label] = true
        }
    })

    const [openSections, setOpenSections] = useState(defaultOpenSections)

    const onClick = (label) => {
        const isOpen = !!openSections[label]
        if (allowMultipleOpen) {
            setOpenSections({
                ...openSections,
                [label]: !isOpen,
            })
        } else {
            setOpenSections({
                [label]: !isOpen,
            })
        }
    }

    return (
        <div className={accordionStyles.accordion}>
            {children.map((child) => (
                <AccordionSection
                    isOpen={!!openSections[child.props.label]}
                    number={child.props.number}
                    label={child.props.label}
                    sublabel={child.props.sublabel}
                    onClick={() => onClick(child.props.label)}
                    key={child.props.number + child.props.label}
                    isQuiz={child.props.isQuiz}
                    isLesson={child.props.isLesson}
                >
                    {child.props.children}
                </AccordionSection>
            ))}
        </div>
    )
}

Accordion.propTypes = {
    children: PropTypes.instanceOf(Object).isRequired,
    allowMultipleOpen: PropTypes.bool,
}

export default Accordion
