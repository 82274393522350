import React from 'react'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid' // Used to generate key
import * as tagsStyling from './tags.module.scss'

const Tags = ({ tags }) => {
    return (
        <div className={tagsStyling.tagsWrapper}>
            {tags.map((tag) => (
                <p key={uuidv4()} className={tagsStyling.tag}>
                    {tag}
                </p>
            ))}
        </div>
    )
}

Tags.propTypes = {
    tags: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default Tags
