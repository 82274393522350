import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Cta from './common/cta'
import Card from '../components/card'
import * as previewArticleBlock from './previewArticleBlock.module.scss'
import { preventWidow } from '../utils/helpers'

const PreviewArticleBlock = () => {
    const data = useStaticQuery(graphql`
        query {
            allContentfulArticle(
                limit: 8
                sort: { fields: [createdAt], order: DESC }
            ) {
                edges {
                    node {
                        slug
                        createdAt(formatString: "D MMMM YYYY", locale: "sv")
                        id
                        title
                        subtitle
                        quiz {
                            id
                        }
                        category {
                            title
                        }
                        image {
                            title
                            gatsbyImageData(
                                width: 960
                                height: 600
                                cropFocus: CENTER
                                placeholder: BLURRED
                                quality: 90
                                resizingBehavior: FILL
                            )
                        }
                    }
                }
            }
        }
    `)
    return (
        <section className={previewArticleBlock.previewArticleBlockWrapper}>
            <div className={previewArticleBlock.headline}>
                <h2>Senaste artiklarna</h2>
                <p>
                    {preventWidow(
                        'Håll dig uppdaterad och utöka din kunskap om vin genom att läsa våra artiklar!'
                    )}
                </p>
            </div>
            <div className={previewArticleBlock.articlesWrapper}>
                {data.allContentfulArticle.edges.map(({ node: article }) => (
                    <Card
                        key={article.id}
                        to={article.slug}
                        title={article.title}
                        subtitle={'Publicerat ' + article.createdAt}
                        tags={[article.category.title]}
                        bodyText={article.subtitle}
                        image={article.image}
                        showBanner={!!article.quiz}
                        bannerText={'+ quiz'}
                    />
                ))}
            </div>

            <div className={previewArticleBlock.showAllLink}>
                <Cta isLink={true} to="/artiklar" text="Se alla artiklar" />
            </div>
        </section>
    )
}

export default PreviewArticleBlock
