import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import SectionContainer from './layout/sectionContainer'
import Accordion from './common/accordion'
import Cta from './common/cta'
import { BookOpen, HelpCircle } from 'react-feather'
import PropTypes from 'prop-types'
import * as accordionSectionStyles from './common/accordionSection.module.scss'

const PreviewSchool = ({ firstOpen }) => {
    const data = useStaticQuery(graphql`
        query {
            allContentfulSchool {
                edges {
                    node {
                        id
                        title
                        courses {
                            id
                            title
                            description {
                                description
                            }
                            slug
                            learnings
                            classes {
                                id
                                title
                                quiz {
                                    id
                                }
                            }
                        }
                    }
                }
            }
        }
    `)

    const school = data.allContentfulSchool.edges[0].node
    const courses = school.courses

    const renderClasses = (classes) => {
        return classes.map((currentClass) => (
            <li key={currentClass.id}>
                <>
                    <span className={accordionSectionStyles.introduction}>
                        {currentClass.quiz ? (
                            <>
                                <HelpCircle />{' '}
                                <span
                                    className={
                                        accordionSectionStyles.introductionText
                                    }
                                >
                                    Quiz
                                </span>
                            </>
                        ) : (
                            <>
                                <BookOpen />{' '}
                                <span
                                    className={
                                        accordionSectionStyles.introductionText
                                    }
                                >
                                    Lektion
                                </span>
                            </>
                        )}
                    </span>
                    <strong>{currentClass.title}</strong>
                </>
            </li>
        ))
    }

    return (
        <SectionContainer
            title={'Vinskola'}
            subtitle={
                'Här hittar du kurser som består av flera delar.\n Varje kurs innehåller flera delar som du gör i din egen takt.'
            }
        >
            <Accordion allowMultipleOpen={true}>
                {courses.map((course, index) => (
                    <div
                        key={course.id}
                        label={course.title}
                        sublabel={course.description.description}
                        number={index + 1}
                        isOpen={firstOpen && index === 0}
                    >
                        <p>Kursen består av följande delar:</p>
                        <ol>{renderClasses(course.classes)}</ol>
                        <Cta
                            text={'Till kursen'}
                            isLink={true}
                            to={course.slug}
                        />
                    </div>
                ))}
            </Accordion>
        </SectionContainer>
    )
}

PreviewSchool.propTypes = {
    firstOpen: PropTypes.bool.isRequired,
}

export default PreviewSchool
