import React from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Hero from '../components/hero'
import PreviewArticleBlock from '../components/previewArticleBlock'
import WineCarousel from '../components/wineCarousel'
// import InstagramFeed from "../components/instagramFeed";
import PreviewSchool from '../components/previewSchool'

const IndexPage = () => {
    return (
        <>
            <GatsbySeo
                title="Din digitala vinskola"
                description="Vill du lära dig i din egen takt om hur man tillverkar vin på olika sätt, om olika druvor och regioner samt få tips på vin? Då har du kommit rätt!"
            />
            <Hero
                heading="Välkomen till Vinologik!"
                subheading="Din digitala vinskola"
                body=" Vill du lära dig i din egen takt om hur man tillverkar
                vin på olika sätt, om olika druvor och regioner samt få
                tips på vin? Då har du kommit rätt!  <br/> <br/> Här kan du gå vår vinskola som består av olika klasser
                där du lär dig om vin på olika nivåer, från nybörjare
                till avancerat. Om du redan känner dig bekväm i
                grunderna kan du läsa våra fristående kurser och
                artiklar. Vår ambition är att Vinologik ska erbjuda
                kunskap för alla."
                ctaText="Gå till vinskolan"
                ctaLink="/vinskola"
            />
            <PreviewSchool firstOpen={true} />
            <PreviewArticleBlock />
            <WineCarousel />
            {/* <InstagramFeed /> */}
        </>
    )
}

export default IndexPage
